import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

class OfficesNav extends React.Component {
  

  offices(office, index) {
    let content = []
    this.props.offices.edges.map((office, index) => {
      if (!office.node.venture) {
        content.push(
          <Link
            key={index}
            to={`/about/office/${office.node.slug}`}
            activeClassName="is-active"
            className="nav-item"
          >
            {office.node.officeName}
          </Link>
        )
      }
    })
    return content
  }

  render() {
    return <nav className="nav offices__nav is-center">{this.offices()}</nav>
  }
}

OfficesNav.propTypes = {
  offices: PropTypes.object.isRequired,
}

export default OfficesNav
